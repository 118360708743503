import axios from 'axios';
import { useApplicationStore } from '../stores/Application';
import { Container } from '../models/Container';
let instance: Api;

class Api {
    private BASE_URL = 'https://sample-inventory-api.crownbio.com';

    constructor() {
        if (instance) {
            throw new Error('You can only create one instance!');
        }
        axios.interceptors.request.use(function (config) {
            const token = useApplicationStore.getState().authToken;
            if (token !== undefined && token !== '') {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
        instance = this;
    }

    async Login(userName: string, password: string): Promise<string> {
        try {
            const uninterceptedAxiosInstance = axios.create();
            const axiosResponse = await uninterceptedAxiosInstance.post(
                `${process.env.REACT_APP_OAUTH_API_URL}/auth/login`,
                { userName, password },
                { headers: { Authorization: `Basic ${process.env.REACT_APP_OAUTH_API_AUTHORIZATION}` } }
            );
            const token = axiosResponse.data.accessToken;
            return token;
        } catch (error) {
            // TODO: Log error to the backend ??
            console.error(error);
            throw error;
        }
    }

    async GetRacksForContainer(containerId: number): Promise<Array<any>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Racks?containerId=${containerId}`);
        return response.data;
    }

    async GetBoxesForRack(rackId: number): Promise<Array<any>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Boxes?rackId=${rackId}`);
        return response.data;
    }

    async SetBoxSize(boxId: number, boxSize: number): Promise<Array<any>> {
        return axios.put(`${this.BASE_URL}/SampleInventory/v1/Boxes?boxId=${boxId}&boxSize=${boxSize}`, ' ');
    }

    async GetStorageSites(): Promise<Array<any>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/StorageSites`);
        return response.data;
    }

    async GetStorageTypes(): Promise<Array<any>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/ContainerTypes`);
        return response.data;
    }

    async GetStorages(): Promise<Array<Container>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Containers`);
        return response.data;
    }

    async GetStoragesByTypeAndSiteId(containerTypeId: number, storageSiteId: number): Promise<Array<Container>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Containers/ByTypeAndStorageSite?containerTypeId=${containerTypeId}&storageSiteLocationId=${storageSiteId}`);
        return response.data;
    }

    async GetStoragesByType(containerTypeId: number): Promise<Array<Container>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Containers/ByType?typeId=${containerTypeId}`);
        return response.data;
    }

    async GetStoragesBySite(storageSiteId: number): Promise<Array<Container>> {
        const response = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Containers/ByStorageSite?storageSiteId=${storageSiteId}`);
        return response.data;
    }

    async GetSamplesForBox(containerName: string, rackName: string, boxName: string): Promise<Array<any>> {
        const responseCb = await axios.get(`${this.BASE_URL}/SampleInventory/v1/Samples?containerName=${containerName}&rackName=${rackName}&boxName=${boxName}`);

        const concatArr = [].concat(responseCb.data.filter((x: any) => x.isIndivuTypeReserved === false && x.isIndivumedTherapeutics === false));

        const map = new Map(concatArr.map((sample: any) => [sample.locationPos, sample]));
        return [...map.values()];
    }
}

const BackendApi = Object.freeze(new Api());
export default BackendApi;
